<template>
<transition name="fade">
    <div class="background" ref="modal" tabindex="0" @keydown.esc="closeDialog"  >
        <div class="foreground" >
            Are you sure about that?
            <flex-row class="button-row">
                <button class='delete' @click="deleteProject">Delete</button>
                <button class="cancel" @click="closeDialog">Cancel</button>
            </flex-row>
            <flex-row class="spaced" >
                <span>Quit Asking</span> 
                <quit-check-box class="check-box"/>
            </flex-row>
        </div>
    </div>
</transition>
</template>

<script>
import QuitCheckBox from "../manage/QuitCheckBox.vue";

export default {
    components: { QuitCheckBox },
    emits: ["close", "delete-dom"],
    props: ["id", "i"],
    methods: {
        async deleteProject() {
            this.$emit("delete-dom", this.i)
            await this.$store.dispatch('deleteProject', this.id); 
            await this.$store.dispatch('fetchProjects')
            this.closeDialog()
        },
        closeDialog() {
            this.$emit("close")
        }
    },
    mounted() {
        this.$refs.modal.focus()
    }
}
</script>

<style lang="stylus" scoped>
@keyframes background-transition {
    0% {
        background-position 1% 0%
    }
    50% {
        background-position 99% 100%
    }
    100% {
        1% 0%
    }
}
    div.background
        background-color: var(--modal-background)
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        z-index: 1;
    
        div.foreground
            background-image: (linear-gradient(to left, hsla(208, 40%, 50%, 1), hsla(312, 40%, 50%, 1)));
            animation: background-transition 9s ease infinite;
            background-size: 400% 400%;
            color var(--text)
            font-size 3vmin
            border-radius: 5px;
            box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
            width 50 vmin
            margin-left: auto;
            margin-right: auto;
            padding: 1rem;

            div.button-row 
                justify-content space-evenly
                margin 4vmin 0

                button.delete
                    padding 1vmin 3vmin
                    font-family: var(--font)
                    color: var(--text)
                    border: none
                    border-radius: 7px;
                    font-size: 4vmin
                    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
                    
                    background-color var(--warn)

                    &:hover 
                        transform scale(1.05)
                        cursor pointer
                        box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);

                button.cancel
                    align-self flex-end
                    padding .8vmin 2.7vmin
                    height 6vmin
                    font-family: var(--font)
                    background-color var(--charcoal)
                    color: var(--text)
                    border: none
                    border-radius: 7px;
                    font-size: 3vmin
                    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
                    
                    &:hover 
                        transform scale(1.05)
                        cursor pointer
                        box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);

            div.spaced 
                justify-content center

                .check-box 
                    align-self center
                    margin-left 1.2rem

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>