<template>
<div>
    <spinner 
        v-if="loading" 
        bg='panel'
    />
    <main-panel >
        <h3>Project: {{projectName}}</h3>
        <subject-search-bar />
            
        <div v-if="hasSubjects" class="listContainer" >
            <subject-selects v-if="hasGroups"/>
            <template  v-for="(subject, i) in subjects" :key='i' class="list" >
                <div class="grid">    
                    <list-item class="center" :subject='subject' name="code" />
                    <toggle-tip  class="right" v-if="i===0" contentSide="right" content="Go to subject information and code"/>
                </div>
            </template>
        </div>
        <div v-if="noFilters && hasGroups">
            <h1>Make a selection or search for a subject. </h1>
        </div>
        <div v-if="!hasSubjects">
            <h2>This Project has no subjects.</h2>
            <ul>
                <li><h4>Add subjects individually with the add subjects button.</h4></li> 
                || or ||  
                <li><h4>Reupload your CSV.</h4></li>
            </ul>
        </div>
        <router-link alt="add subjects link" to="/addsubject"   
            class="add-subject-link"
        >    
            <img src="../../public/img/add-subject.svg" alt="add-user icon">
            <div class="link-title">Add Subject</div>
        </router-link>
    </main-panel>

    <toast v-if="newMsg" offset="true"/>

</div>
</template>

<script>
import SubjectSelects from "../components/list/SubjectSelects.vue"
import ListItem from "../components/list/ListItem.vue"
import SubjectSearchBar from "../components/list/SubjectSearchBar.vue"
export default {
    data() {
        return {
        }
    },
    components: {
        SubjectSelects, ListItem, SubjectSearchBar
    },
    computed: {
        subjects() {
            console.log('subjects getter length:', this.$store.getters.searchSubjects.length);
            return this.$store.getters.searchSubjects
        },
        projectName() {
            return this.$store.getters.getProjectName
        },
        hasSubjects() {
            return this.$store.state.hasSubjects
        },
        loading() {
            console.log('listSubPage loading', this.$store.state.uiStore.status.subjects.loading);

            return this.$store.state.uiStore.status.subjects.loading
        },
        noFilters() {
            let bool = false
            if (Object.keys(this.$store.state.groups).length > 0) {bool=false}
            if( this.$store.state.uiStore.select0.value === null &&
                this.$store.state.uiStore.select1.value === null &&
                this.$store.state.uiStore.select2.value === null &&
                this.$store.state.uiStore.query.length === 0
            ) {bool = true} else {bool = false}
            return bool
        },
        hasGroups() {
            let bool = false
            if(Object.keys(this.$store.state.groups).length > 0) {
                bool = true
            }
            return bool
        },
        newMsg() {
            return this.$store.state.uiStore.isNewMsg
        }
    },
}
</script>

<style scoped lang='stylus'>
    h3 
        margin 1rem
        font-size 5vmin

    div.listContainer
        display flex
        flex-flow column
        align-items center
        font-size calc(1rem + 1vw)
        font-family: var(--font)
        text-align center
        margin-top 1rem

        .grid
            display grid
            grid-template-columns 1fr 1fr 1fr 

            .right 
                margin-left .5rem
                margin-bottom .7rem
                margin-right auto

    a.add-subject-link
        position sticky
        bottom 1vmax 
        margin-right 1vmax
        margin-left auto
        display flex
        flex-flow row
        align-items center
        padding .4rem
        background var(--panel)
        border-radius 7px
        text-decoration none 
        color var(--title)
        font-family var(--font)
        font-size 2vmin
        
        &:hover
            cursor pointer 
            transform scale(1.05)
            background-color var(--brighter-panel)
    
        img 
            margin-right .5rem
            height 4vmin
            filter var(--svg-filter)





</style>