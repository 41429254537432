<template>
<div>
    <spinner 
        v-if="loading" 
        bg='panel'
    />

    <main-panel class="left-align">
        <template v-for="(project, i) in projects" :key="i">
            <div :class="{'first-grid': i===0&&tips}" >
                <button :class="{'first-project': i===0}" alt="look at the subjects of this project button" class="project-link" @click="setProject(project.id,  project.owner)">
                    <div class="project-name">{{project.name}}</div>
                    <div class="details">
                        <div>Expiration: {{project.expiration}}</div>
                    </div>
                </button>
                <toggle-tip v-if="i===0" contentSide="left" content="Click to go to a list of subjects in this project."/>
            </div>
        </template>
    </main-panel>

    <toast v-if="newMsg" offset="true"/>
    
</div> 
</template>

<script >
export default {
    methods: {
        async setProject(id) {
            this.$store.commit('setProjectId', id );
            this.$store.commit('setSelectsAll')
            await this.$store.dispatch('fetchSubjects', id );
            this.$router.push({name: 'listSubjects', params: {id: id}});
        }
    },
    computed: {
        projects() {
            return this.$store.state.projects
        },
        loading() {
            return this.$store.state.uiStore.status.subjects.loading
        },
        tips() {
            return this.$store.state.uiStore.isTips
        },
        newMsg() {
            return this.$store.state.uiStore.isNewMsg
        }
    },
    async beforeCreate() {
        if(this.$store.state.projects < 1) {
            let res = await this.$store.dispatch('fetchProjects') 
            if(res === '💀') {
                this.$router.push('createProject')
            }
        }
    }
    
}
</script>

<style scoped lang='stylus'>
div.left-align
    align-items start 
    
    button.project-link
        color: var(--text)
        text-decoration: none;
        font-family: var(--font)
        background var(--brighter-panel)
        border-radius 3px
        width 69vw 
        min-height 8vh
        margin 1.5vh 0
        border none
        display flex
        justify-content space-between

        &:hover 
            transform scale(1.02)
            cursor pointer 
            opacity 1

        div.project-name    
            font-size 5.2vmin 
            align-self center
            margin-left .8rem
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

        div.details
            font-size 2.8vmin
            align-self center
            margin-right .5rem

    div.first-grid
        display grid 
        width 69vw
        grid-template-columns 1fr 1fr

        button.first-project 
            width 65vw 

        span.toggletip-container 
            margin-left 1rem


</style>