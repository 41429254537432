<template>
<div>
    <!-- <Nav/>
    <side-bar/> -->
    
    <main-panel>
        <h1>
            You've always needed me.
        </h1>
        <ul>
            <li>CSV formatting info</li>
            <li>Stuff pointing out the obvious way to manage things</li>
            <li>FAQ</li>
            <li>legal info</li>
        </ul>
    </main-panel>

</div>
</template>



<style lang="stylus" scoped>

</style>