<template>
        <div 
            @keydown.esc="close" 
            class="toast-container up" 
            :class="{'red': msg.includes('Error'), 'offset': this.offset}"
        >
            <h2>{{msg}}</h2>
            <button  @keydown.esc="close" @click="close" type='button' class="close" alt="close network message popup">
                <img src="../../../public/img/close.svg" alt="close icon">
            </button>
        </div>
</template>

<script>

export default {
    props: ['offset'],
    methods: {
        close() {
            this.$store.commit('setIsNewMsg', false)
        }
    },
    computed: {
        msg() {
            return this.$store.state.uiStore.networkMsg
        },
        isToast() {
            return this.$store.state.uiStore.isNewMsg
        }
            
    }   
    
}
</script>

<style lang="stylus" scoped>


    div.toast-container
        display block
        width 30vw
        // max-width 300px
        padding-left 2rem 
        padding-right 2rem
        border-radius 7px
        background-color var(--toast-success)
        font-size 1.8vmin
        font-family var(--font)
        color var(--text)
        position fixed
        left 50% - 15vw
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
        text-align center

        button.close 
            border none
            background none
            position absolute
            top 0 
            right 0

            img 
                width 4vmin

                &:hover
                    transform scale(1.2)
                    cursor pointer
    div.offset 
        left 50% - 2vw

    div.toast-container.red 
        background var(--warn)

    div.up
        opacity 100%
        bottom 1vmax
        animation toast-up
        animation-duration .4s
        animation-iteration-count 1
        animation-timing-function ease-out

@keyframes toast-up
    0% {bottom: -1vmax; opacity: 0;}
    100% {bottom: 1vmax; opacity: 1;}

@keyframes toast-down
    0% {bottom: 1vmax; opacity: 1;}
    100% {bottom: -1vmax; opacity: 0;}

@media screen and (orientation:portrait)   
    div.toast-container 
        left 50% - 15vw
    
    

    

</style>