<template>
    <centered-panel>
        <h1>
            <span>4<img src="../../public/img/blue.svg" alt="logo">4</span>
        </h1>
        <h2>Page not found</h2>
        <router-link alt="login page link" to="login">Try logging in</router-link>
        <div>Or</div>
        <router-link alt="projects page link" to="projects">Photoshoot page</router-link>
    </centered-panel>
</template>

<script>
import CenteredPanel from '../components/ui/CenteredPanel'
export default {
    components: { CenteredPanel }
}
</script>

<style lang="stylus" scoped>
    @keyframes spin 
        0% { transform: rotate(0deg) }
        100% { transform: rotate(360deg) }
    
    div
        color var(--text)
        font-family var(--font)

        h1
            margin-top 0
            margin-bottom 0
            font-size 10rem
            line-height 9rem
            img 
                width: 7.4rem;
                height: 7.4rem;
                animation: spin 6s
                &:hover 
                    transform: rotate(720deg);
                    transition: transform 1.5s ease-in-out;

        h2 
            font-size 2.5rem
            margin-top 0
            margin-bottom 6vh
        a  
            text-decoration none 
            font-size 2rem
            color var(--text)
            border-radius 7px
            padding .5rem
            background var(--brighter-panel)
            
            &:hover
                background var(--accent)

        div
            margin 2rem 0

    
</style>