<template>
    <main-panel>
        <div class="inner-panel">
            <form id="create-project" @keyup.enter="submitProject">
                <label >Project Name</label>
                <input v-model.trim="shootName" type="text"  >
                <h2>In addition to first and last names, enter up to 3 additional categories for subjects:</h2>
                <label >Category 1</label>
                <input v-model.trim="cat1"  type="text"  >
                <label >Category 2</label>
                <input v-model.trim="cat2" type="text"  >
                <label >Category 3</label>
                <input v-model.trim="cat3"  type="text"  >
                
                

            </form>
            <button  v-if="this.shootName != null" alt="create project" type='button' class="submitBtn" @click="submitProject">
                Submit
            </button>
        </div>

    </main-panel> 
</template>

<script>
export default {
    data() {
        return {
            shootName: null,
            cat1: '',
            cat2: '',
            cat3: ''
        }
    },
    methods: {
        async submitProject() {
            await this.$store.commit('setProjectName', this.shootName)
            let payload = [this.cat1, this.cat2, this.cat3].filter((e) => {
                return !!e.trim()
            })
            console.log('payload: ', payload);
            await this.$store.dispatch('createProject', payload)
            await this.$store.dispatch('fetchProjects')
            this.$router.push({ name: "projects" });
        }
    }
}
</script>

<style scoped lang="stylus">
.inner-panel
        background var(--brighter-panel)
        border-radius 7px
        width 70vw
        display flex 
        flex-flow column
        align-items center

        form 
            display flex 
            flex-flow column
            align-items center
            
            label
                border none
                font-size: 2rem
                &:first-child
                    margin-top 2rem
                    
            input
                -webkit-appearance: none; 
                appearance: none;
                width: 20rem
                font-size 1.4rem
                padding: 10px 20px;
                margin-bottom 1.5rem
                display: inline-block;
                border solid 2px var(--border)
                border-radius: 7px;
                box-sizing: border-box;
                color var(--text)
                text-align center
                background-color: var(--panel)
                font-family var(--font)
                
                &:focus
                    background var(--bg)
                    
                &::placeholder 
                    opacity 1
                    text-align center
                    color var(--text)

            h2 
                margin 1rem 3rem

        button.submitBtn
            -webkit-appearance: none; 
            appearance: none;
            background var(--charcoal)
            color var(--text-color)
            font-family: 'Roboto Slab', serif; 
            font-size calc(.7rem + .7vw)
            border none 
            border-radius 7px
            box-shadow 1px 1px 3px 1px rgba(0,0,0,.5)
            width 20vw
            margin 2rem

            &:hover
                cursor pointer
                transform scale(1.1)
    
    select.form-select::placeholder
        text-align center
</style>