<template>
<div class="master-container">
    <Nav v-if='pageWithNav'/>
    <side-bar v-if='pageWithNav'/>
	<router-view v-slot="slotProps">
		<transition name='route' mode="out-in">
			<component :is="slotProps.Component" class="page"></component>
		</transition>
	</router-view>
</div>
</template>

<script>
import Nav from './components/ui/Nav'
import SideBar from './components/ui/SideBar'
export default {
    components: { Nav, SideBar },
    computed: {
        pageWithNav() {
            if (this.$route.path === '/login' || 
                this.$route.name === 'code' ||
                this.$route.name === 'not-found' ||
                this.$route.path === '/test'
            ) {
                return false
            } else {
                return true
            }
        }
    },
    created() {
        const currentTheme = sessionStorage.getItem('theme') ? sessionStorage.getItem('theme') : null;

        if (currentTheme) {
            document.documentElement.setAttribute('data-theme', currentTheme);
        }
        
        this.$store.dispatch('watchSession')

    }
};
</script>

<style lang='stylus'>
div.master-container 
    display grid

:root
    --bg: hsla(264, 49%, 11%, 1)
    --bg-opacity : hsla(264, 41%, 25%, 1)
    --modal-bg: hsla(0, 0, 0, 0.3)
    --text: hsla(0, 0%, 92%, 1)
    --title: hsla(0, 0%, 90%, 1)
    --panel: hsla(0, 0%, 100%, 6%)
    --panel-solid: hsla(190, 50%, 30%, 1)
    --brighter-panel: hsla(0, 0%, 100%, 10%)
    --brightest-panel: hsla(0, 0%, 100%, 20%)
    --border: hsla(0, 0%, 86%, .8)
    --accent: hsla(243, 40%, 45%, 1)
    --charcoal: hsla(266, 6%, 23%, 1)
    --warn: hsla(0, 100%, 35%, 1)
    --title-font: bc-alphapipe, sans-serif 
    --font: 'Protipo', 'Montserrat', 'Open Sans'
    --svg-filter: brightness(1)
    --greyed-out: hsla(0, 0%, 100%, 1%)
    --greyed-out-text: hsla(0, 0%, 100%, 9%)
    --greyed-svg: brightness(.4)
    --toast-success: hsla(100 50% 30% 1)

[data-theme="light"]
    --bg: hsla(264, 49%, 91%, 90%)
    --bg-opacity : hsla(264, 41%, 91%, 80%)
    --text: hsla(0, 0%, 7%, .93)
    --title: hsla(0, 0%, 8%, 1)
    --panel: hsla(0, 0%, 10%, 10%)
    --panel-solid: hsla(30, 60%, 80%, 1)
    --brighter-panel: hsla(0, 0%, 20%, 20%)
    --brightest-panel: hsla(0, 0%, 30%, 35%)
    --border: hsla(0, 0%, 7%, .8)
    --accent: hsla(360, 100%, 75%, 0.64) 
    --charcoal: hsla(360, 100%, 94%, 0.64)
    --warn: hsla(0, 100%, 60%, 1)
    --svg-filter: invert(0.4) sepia(0) saturate(1) hue-rotate(0deg) brightness(0.5)
    --greyed-out: hsla(0, 0%, 10%, 3%)
    --greyed-out-text: hsla(0, 0%, 7%, .33)
    --greyed-svg: brightness(.8)
    --toast-success: hsla(110 70% 70% 1)

html 
    box-sizing: border-box;
    overflow-y auto

*, *:before, *:after {
    box-sizing: border-box;
}

body
    margin 0
    background: var(--bg)

.route-enter-from
    opacity 0

.route-leave-to
    opacity 0

.route-enter-active
    transition all .2s ease-out
.route-leave-active
    transition all .2s ease

.route-enter-to,
.route-leave-from 
    opacity 1

@font-face {
    font-family: NotoMono;
    src: url('../public/fonts/NotoMono-Regular.ttf') format('truetype');
}

</style>
