<template>
<div>
    <main-panel>
        <div class="inner-panel">
                    
            <file-select />
            <h2>or</h2>
            <button @click="this.$router.push('/manual')">Manually create a project </button>
        </div>
    </main-panel>
</div>
</template>

<script>
import FileSelect from '../components/create-shoot/FileSelect'
export default {
    components: { FileSelect },
}
</script>

<style scoped lang='stylus'>

div.inner-panel
    background var(--brighter-panel)
    border-radius 7px
    margin 2vmin 1vmin 0 1vmin
    display flex
    flex-flow column
    align-items center
    justify-content center

    button  
        padding 2vmin
        background var(--accent)
        border-radius 7px
        border none
        margin 3vmin 2vmin

        color var(--text)
        font-size 4vmin
        font-family var(--font)
        display flex 
        flex-flow row
        align-items center

        &:hover
            cursor pointer


    h2 
        margin 0 
        padding 0
        font-size 4vmin
    




</style>