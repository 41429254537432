<template>
    <div>
        <input alt="search for subject by typing last name" v-model="getQuery" type="search" placeholder="Search for a subject by last name." autofocus>
    </div>
</template>

<script>
export default {
    computed: {
        
        getQuery: {
            get() {
                return this.$store.state.uiStore.query
            },
            set(payload) {
                this.$store.commit('setQuery', payload)
            }
        }
    },
    
}
</script>

<style lang="stylus" scoped>
input 
    width: 50vmin
    height 7vmin
    font-size: 3vmin
    border solid 2px var(--border)
    border-radius: 1.2vmin;
    color var(--text)
    text-align center
    background-color: var(--panel)
    font-family var(--font)
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:focus
        background var(--charcoal)
        
    &::placeholder 
        opacity 1
        text-align center
        color var(--text)

</style>