<template>
    <div class='selected-file-container'>Selected File: 

        <div class='file-name-row'>
            <p>{{file.name}}&nbsp; </p>
            <trash-btn alt="delete this csv file" type="button" @click.prevent="removeFile" size='three'/>
        </div>
        <form type="submit" @keydown.enter.prevent="submitFile">
            <input 
                alt="photoshoot name"   
                type="text" 
                placeholder="Name your photoshoot" 
                v-model.trim="shootName"
            >
        </form>
            <!-- <label for="date">Date of photoshoot</label> -->
            <!-- <input type="date" placeholder="Photoshoot Date" v-model.trim="shootDate"> -->
        <upload-btn 
            alt="upload csv with this name"
            type="button" 
            @click.prevent="submitFile" 
            v-if="shootName" 
        />
            

        <div class="validation-message" v-if="!formIsValid">{{validationMessage}}</div>
    </div>    
</template>

<script>
import TrashBtn from'@/components/ui/TrashBtn.vue'
import UploadBtn from '@/components/ui/UploadBtn.vue' 
export default {
    components: { UploadBtn, TrashBtn },
    props: [ "file" ],
    data() {
        return {
            formIsValid: true,
            validationMessage: "Make sure to name and date your photoshoot and choose a compatible file.",
            shootName: null,
            // shootDate: null,
        }
    },
    methods: {
        removeFile() {
            this.$emit('remove')
        },
        async submitFile() {
            await this.$store.dispatch('parseCSV', this.file)
            this.$store.commit('setProjectName', this.shootName)

            this.$router.push({path: '/table'})
        }
    },
    
}
</script>

<style lang="stylus" scoped>
div.selected-file-container
        font-size 3vmin
        padding: 1vmin
        margin 0 1vmin
        // width 55vw
        color: white;
        background-color: var(--accent)
        font-family var(--font)
        border-radius: .3rem;
        text-align: center;
        overflow hidden
        border-radius 7px

    div.file-name-row
        display flex
        flex-flow row
        justify-content center
        align-items baseline
        
        p 
            font-size 4vmin
            margin 1rem 0 2rem 0 
    
        button.delete-button
            padding 0
            font-size 1.5rem
            font-weight bold
            background none
            border none

            &:hover
                cursor pointer
                transform scale(1.1)



    input 
        // width: 20rem
        font-size: 3vmin;
        padding: .6rem 1rem
        margin: 0 0 .5rem 0
        display: inline-block;
        border solid 2px var(--border) 
        border-radius: 7px;
        box-sizing: border-box;
        color var(--text)
        text-align center
        background-color: var(--accent)
        font-family var(--font)

        &:focus
            background var(--bg)
            
        &::placeholder 
            opacity 1
            text-align center
            color var(--text)

    label
        display block
        margin 1.2rem 0 .2rem 0
        font-size 1.2rem

    input[type="date"]::-webkit-calendar-picker-indicator
        display none

    

    div.validation-message
        margin-top 3rem
        color var(--warn)
        font-size 1rem
        font-family var(--font)  

</style>