<template>
    <button alt="delete button" class='trash-btn'  type="button">
        <img :class="this.size" src="../../../public/img/delete.svg" alt="trash icon">
    </button>
</template>

<script>
export default {
    props: ['size']
}
</script>

<style lang="stylus" scoped>
    button.trash-btn 
        padding 0
        font-size 1.5rem
        font-weight bold
        background none
        border none
        margin 0

        img 
            vertical-align: middle
            filter var(--svg-filter)

        &:hover
            filter brightness(0.3) sepia(1) hue-rotate(-40deg) saturate(12)
            cursor pointer
        
        img.two 
            height 4vmin

        img.three 
            height 5vmin
</style>