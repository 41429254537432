<template>
<div class="login-container">
    
    <centered-panel >

        <spinner bg='login' v-if="loading"/>
        <logo v-else />

        <h1 class='site-title'>Shutter<span>Sort</span></h1>

        <!-- <h3 class="login-title">Login</h3> -->
        <login-form/>
        
    </centered-panel>

    <toast v-if="newMsg"/>
    
</div>
</template>

<script>

import CenteredPanel from '../components/ui/CenteredPanel'
import Logo from '../components/login/Logo'
import LoginForm from '../components/login/LoginForm'

export default {
    components: { CenteredPanel, Logo, LoginForm },
    data() {
        return {
            loginTab: true,
        }
    },
    computed: {
        loading() {
            return this.$store.state.uiStore.status.login.loading
        },
        newMsg() {
            return this.$store.state.uiStore.isNewMsg
        }
    }
    
    
}
</script>

<style scoped lang='stylus'>



div.login-container 
    overflow hidden

    h1.site-title 
        font-family: var(--title-font)
        font-style: normal;
        font-weight: bold;
        font-size: 3.2rem;
        color: var(--title)
        margin 1rem 0

        span    
            opacity .8

    

</style>