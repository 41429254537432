<template>
    <form key="login" @keyup.enter="login" >
        <input  class="email" :class="{'longEmail': hideBackground}" type="text" placeholder="Email" name="email" v-model.trim="email">
        <div class="password-row">
            <input class="password"  :type="passwordFieldType" placeholder="Password" name="password" v-model.trim="password">
            <show-hide-btn 
                @togglepw="passwordFieldType = passwordFieldType === 'password' ? 'text' : 'password'"
            />
        </div>
    </form>

    <div class="button-row">
        <button alt="reset password button" type="button" @click="reset">Reset Password</button>
        <button alt="login button" type="button" @click.prevent="validate">
            Login
        </button>
    </div>

</template>

<script>
import ShowHideBtn from "./ShowHideBtn.vue";

export default {
    components: { ShowHideBtn },
    data() {
        return {
            email: null,
            password: null,
            formIsValid: true,
            passwordFieldType: 'password',
            hideBackground: false,
        }
    },
    methods: {
        reset() {
            this.$store.dispatch('resetPassword', this.email)
        },
        validate() {
            this.$store.commit('setLoading', {'dataName': 'login', 'status': true})
            this.formIsValid = true;
            if (
                this.email === null ||
                this.email.length < 8 ||
                !this.email.includes('@') ||
                this.password === null ||
                this.password.length < 8
            ) {
                this.$store.commit('setLoading', {'dataName': 'login', 'status': false})
                this.formIsValid = false;
                this.$store.dispatch('callToast', "Error: Please enter valid email and password.")
                return;
            } else { this.login()}
        },
        async login() {
            await this.$store.dispatch('login', {email: this.email, password: this.password})
            await this.$store.dispatch('fetchProjects')
            if(this.$store.state.hasProjects) {
                await this.$router.push({path: '/projects'})
            } else {
                await this.$router.push({path: '/createProject'})
            }
        }

    },

    watch: {
        email: function (val) {
            if (val.length > 16) {
                this.hideBackground = true
            } else {
                this.hideBackground = false
            }
        }

    }
    
}
</script>

<style lang="stylus" scoped>
    form 
        display flex
        flex-flow column
        align-items center

        input.email 
            width 18rem
            font-size 1.3rem
            padding .7rem 1.3rem
            margin 1rem
            border none
            border-radius 7px
            color var(--text)
            text-align center
            background-color var(--panel)
            background-image url(../../../public/img/email.svg)
            background-repeat no-repeat
            background-position 1rem
            background-size 1.7rem 1.7rem
            font-family var(--font)

            &:placeholder 
                opacity .6
                text-align center
                color var(--text)

            &:focus::placeholder 
                color transparent

        input.longEmail 
            background-image none

        div.password-row 
            position: relative
            display flex
            flex-flow row
            margin 1rem
            border none
            // background-color var(--panel)

            input.password
                background-image: url(../../../public/img/password.svg)
                background-color var(--panel)
                border none
                background-repeat no-repeat
                background-position 1rem
                background-size 1.7rem 1.7rem
                font-family var(--font)
                color var(--text)
                font-size 1.3rem
                text-align center
                flex-grow 2
                width 18rem
                border-radius 7px
                padding .7rem 1.3rem

                &:placeholder 
                    opacity .6
                    text-align center
                    color var(--text)

                &:focus::placeholder 
                    color transparent

        

    div.button-row 
        position relative
        width 22rem
        display flex
        justify-content center

        button
            padding .4rem .7rem
            width 10rem
            font-family: var(--font)
            background-color var(--charcoal)
            color: var(--text)
            margin-top 1rem
            margin-right .4rem
            border: none
            border-radius: 7px;
            font-size: 1rem;
            box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);

            &:hover 
                transform scale(1.05)
                cursor pointer
                box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);

            &:nth-child(2)
                background var(--accent) 

</style>