import firebase from "firebase/app";

// Import needed firebase modules
import "firebase/auth";

// Firebase app config
const config = {
    apiKey: "AIzaSyBXx0QtZbw4DzXetRmCJjH-nDe0kURkH9c",
    authDomain: "shuttersort-dev.firebaseapp.com"
};

// Init our firebase app
firebase.initializeApp(config);