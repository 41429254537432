<template>
    <div class="nav">
        <span class="title">Shutter<span>Sort</span></span>
        <span class="version">v0.69.1</span>
        <div class="bar-container">
            
        </div>
        <button alt="logout user button" type="button" class="logout" @click="logoutUser">
            Logout
        </button>
        
    </div>

</template>

<script >
export default {
    methods: {
        async logoutUser() {
            await this.$store.dispatch("logout")
            this.$router.push('/login')
        },
    }
}
</script>

<style scoped lang="stylus">
    div.nav 
        top 0
        left 0
        height 8vh
        display flex
        flex-flow row 
        align-items center
        line-height 2rem
        color var(--text)

        span.title 
            font-size 7vmin
            font-family: var(--title-font)
            color var(--title)
            margin-left 2rem

            span    
                opacity .7

        span.version 
            margin-left .3rem
            font-family var(--title-font)
            font-size 2vmin
            height .6rem
            color var(--text)


        div.bar-container 
            width 100%  
            display flex
            flex-flow row
            justify-content center   
        
        button.logout 
            margin-right 1rem
            color var(--title)
            background var(--brighter-panel)
            border none
            padding 1vmin
            border-radius 7px
            font-family var(--font)
            font-size 2.5vmin

            &:hover
                cursor: pointer;
                transform scale(1.1)

    

</style>
