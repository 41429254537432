<template>
    <div class="theme-switch-wrapper">
        <label class="theme-switch" for="theme-checkbox">
            <input 
                alt="switch color theme checkbox" 
                type="checkbox" 
                id="theme-checkbox" 
                @change='changeColor'
            />
            <div class="slider"></div>
        </label>
    </div>
</template>

<script>

export default {
    methods: {
        changeColor() {
            const theme = localStorage.getItem('theme') 
            if (theme == 'light') {
                document.documentElement.setAttribute('data-theme', 'dark');
                localStorage.setItem('theme', 'dark');
            } else {
                document.documentElement.setAttribute('data-theme', 'light');
                localStorage.setItem('theme', 'light')
            }    

        },
    },
    mounted() {
        const theme = localStorage.getItem('theme') 
        if(theme === 'dark') {
            document.getElementById("theme-checkbox").checked = false
        } else if(theme === 'light') {
            document.getElementById("theme-checkbox").checked = true
        }
    }
}
</script>

<style scoped lang="stylus">
div.theme-switch-wrapper 
    display: flex;
    align-items: center;
    margin-top 1rem
    em 
        margin-left: 10px;
        font-size: 1rem;


    label.theme-switch 
        display: inline-block;
        height: 34px;
        position: relative;
        width: 60px;


        input 
            display:none;


        div.slider 
            background-color: var(--accent)
            bottom: 0;
            cursor: pointer;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: .4s;
            border-radius: 7px;



            &:before 
                background-color: #fff;
                bottom: 4px;
                content: "";
                height: 26px;
                left: 4px;
                position: absolute;
                transition: .4s;
                width: 26px;
                border-radius: 35%;




        input:checked + .slider:before 
            transform: translateX(26px);


        



</style>