<template>
<div class="header-container">
    <img v-if="isDark" src="../../../public/img/blue.svg" alt="logo">
    <img v-else src="../../../public/img/magenta-logo.svg" alt="logo">
</div>
</template>


<script>

export default {
    computed: {
        isDark() {
            let bool
            let theme = localStorage.getItem('theme')
            if (theme === "dark" || !theme) {
                bool = true
            } else { bool = false}
            return bool
        },
        loading() {
            return this.$store.state.uiStore.status.login.loading
        },
    }
}

</script>

<style lang="stylus" scoped>
    @keyframes spin 
        0% { transform: rotate(0deg) }
        100% { transform: rotate(360deg) }

    img 
        width: 13rem;
        height: 13rem;
        animation spin 6s 
        animation-iteration-count: 1;
        animation-delay 1s

        &:hover 
            transform: rotate(720deg);
            transition: transform 1.5s ease-in-out;
            
    
</style>