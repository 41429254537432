<template>
<main-panel>
<div class="inner-panel">
    <h1>Edit Subject</h1>
    <h2>Project: {{projectName}}</h2>

    <form @keyup.enter="submitSubject" id="edit-subject">
        <template v-for="(attribute, i) in subject" :key='i'>
                <label>{{attribute[0]}}</label>
                <input type="text" :value="attribute[1]">
        </template>
    </form>

    <button alt="submit edited subject" type='submit' class="submitBtn" @click.prevent="submitSubject">
        Submit
    </button>
</div>
</main-panel>
</template>

<script>
export default {
    methods: {
        async submitSubject() {
            const inputs = document.getElementById("edit-subject").elements
            const payload = {}
            
            for (let i = 0; i < inputs.length; i++) {
                payload[inputs[i].id] = inputs[i].value
            }
            payload.id = this.$route.params.id
            console.log('submitSubject',payload)
            await this.$store.dispatch('editSubject', payload)
            await this.$store.dispatch('fetchSubjects')
            this.$router.go(-1)

        }
    },
    computed: {
        subject() {
            let subject = this.$store.getters.getDestructSubject(this.$route.params.id)
            subject[0][0] = 'First Name'
            subject[1][0] = 'Last Name'
            return subject
        },
        projectName() {
            return this.$store.getters.getProjectName
        }
    }
}
</script>

<style lang="stylus" scoped>
    div.inner-panel
        background var(--brighter-panel)
        border-radius 7px
        width 70vw
        display flex 
        flex-flow column
        align-items center
        
        form 
            display flex 
            flex-flow column
            align-items center
            padding 1.2rem

            label
                border none
                font-size: 1.3rem
                margin-bottom .3rem
                    
            input
                -webkit-appearance: none; 
                appearance: none;
                width: 20rem
                font-size 1.3rem
                padding: 10px 20px;
                margin-bottom 1.2rem
                display: inline-block;
                border solid 2px var(--border)
                border-radius: 7px;
                box-sizing: border-box;
                color var(--text)
                text-align center
                background-color: var(--panel)
                font-family var(--font)
                
                &:focus
                    background var(--bg)
                    
                &::placeholder 
                    opacity 1
                    text-align center
                    color var(--text)
                
        button.submitBtn
            -webkit-appearance: none; 
            appearance: none;
            background var(--charcoal)
            color var(--text-color)
            font-family: 'Roboto Slab', serif; 
            font-size calc(.7rem + .7vw)
            border none 
            border-radius 7px
            box-shadow 1px 1px 3px 1px rgba(0,0,0,.5)
            width 20vw
            margin 2rem

            &:hover
                cursor pointer
                transform scale(1.1)
</style>