<template>
    <div class="tips-switch-wrapper">
        <label class="tips-switch" for="tips-checkbox">
            <input 
                alt="switch tips checkbox" 
                type="checkbox" 
                id="tips-checkbox"   
                @change='changeTips'
            />
            <div class="slider" :class="{'warn': colorSwitch}"></div>
        </label>
    </div>
</template>

<script>

export default {
    data() {
        return {
            colorSwitch: null
        }
    },
    methods: {
        changeTips() {
            const isTips = this.$store.state.uiStore.isTips
            this.colorSwitch = !this.colorSwitch
            if (isTips) {
                this.$store.commit('setTips', false)
            } else {
                this.$store.commit('setTips', true)
            }
        },
    },
    mounted() {
        const isTips = this.$store.state.uiStore.isTips
        if(isTips) {
            this.colorSwitch = false
            document.getElementById("tips-checkbox").checked = false
        } else {
            this.colorSwitch = true
            document.getElementById("tips-checkbox").checked = true
        }
    }
}
</script>

<style scoped lang="stylus">
div.tips-switch-wrapper 
    display: flex;
    align-items: center;
    margin-top 1rem
    em 
        margin-left: 10px;
        font-size: 1rem;


    label.tips-switch 
        display: inline-block;
        height: 34px;
        position: relative;
        width: 60px;

        input 
            display:none;

        div.slider 
            background-color: var(--warn)
            bottom: 0;
            cursor: pointer;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: .4s;
            border-radius: 7px;


            &:before 
                background-color: #fff;
                bottom: 4px;
                content: "";
                height: 26px;
                left: 4px;
                position: absolute;
                transition: .4s;
                width: 26px;
                border-radius: 35%;

        div.slider.warn 
            background-color var(--accent)

            &:before 
                background-color black

    input:checked + .slider:before 
        transform: translateX(26px);


        


        

</style>