export default {
    state: {
        uploadHeaders:[],
        uploadRows: [],
        uploadProjectName: null,
    },
    mutations: {
        setProjectName(state, name) {
            state.uploadProjectName = name
        },
        setHeaders(state, uploadHeaders) {
            const slicedHeaders = uploadHeaders.slice(2,7)
            console.log('headers', slicedHeaders)
            state.uploadHeaders = slicedHeaders
        },
        setRows(state, rows) {
            console.log('',rows)
            state.uploadRows = rows
        },
    },
    actions: {
        async parseCSV({ dispatch, commit }, payload) {
            const token = await dispatch('forceTokenRefresh')
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`)

            const formdata = new FormData();
            formdata.append("data", payload, "name_of_CSV");

            const requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            try { 
                let res = await fetch("https://test-gateway-9ipxk94p.ue.gateway.dev/parse-subject-data", requestOptions)
                let json =  await res.json()
                commit('setHeaders', json.header)
                commit('setRows', json.rows)
                dispatch('callToast', 'CSV successfully parsed. Now choose your filters.')


            } catch (err) {
                dispatch('callToast', `Error: ${err.message}`)
            }
        },

        async createProject({dispatch, rootState, state}, payload) {
            const token = await dispatch('forceTokenRefresh')
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`)
            myHeaders.append("Content-Type", "application/json");
            
            const obj = {
                "owner":rootState.owner,
                "name":state.uploadProjectName,
                "schema":{
                    "header":[]
                }
            }
            console.log('createProj', payload)
            obj.schema.header.push(...payload)
            const raw = JSON.stringify(obj)
            const requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            try {
                let res = await fetch("https://test-gateway-9ipxk94p.ue.gateway.dev/add-project", requestOptions)
                let text = await res.text()
                dispatch('callToast', `Project ${obj.name} created!`)
                return text
            } catch(err) {
                dispatch('callToast', `Error: ${err.message}`)
            }
        },

        async loadSubjects({ dispatch, state, rootState }, payload) {
            console.log('loadsub', payload)
            const project = payload.id
            const isHeaders = payload.headers
            const objToSend = { "subjects": []}
            const keys= state.uploadHeaders
            state.uploadRows.forEach((row) => {
                let sub = {
                    "owner": rootState.owner,
                    "project": project,
                    "firstName": row[0],
                    "lastName": row[1],
                    "groups": {}
                }
                if (isHeaders[0]) { sub.groups[keys[0]] = row[2] }
                if (isHeaders[1]) { sub.groups[keys[1]] = row[3] }
                if (isHeaders[2]) { sub.groups[keys[2]] = row[4] }
                if (isHeaders[3]) { sub.groups[keys[3]] = row[5] }
                if (isHeaders[4]) { sub.groups[keys[4]] = row[6] }
                
                
                objToSend.subjects.push(sub)
            })

            const token = await dispatch('forceTokenRefresh')
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`)    
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify(objToSend);

            const requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            try {
                fetch("https://gateway.audax.dev/load-subjects", requestOptions)
            } catch (err) {
                dispatch('callToast', `Error: ${err.message}`)
                console.log('loadSubjects ERRRR: ', err)
            }
        },


    }

}
