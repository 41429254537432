<template>
    <div class="main-panel" >
        <slot></slot>
    </div>
</template>

<style lang="stylus" scoped>
    div.main-panel
        position fixed
        top 9.6vh
        left 23vw
        width 77vw
        height 90vh
        min-height 90.4vh
        background var(--panel)
        border-top-left-radius 10px;
        display flex
        flex-flow column
        align-items center
        overflow-y auto
        overflow-x hidden
        font-family var(--font)
        color var(--text)
        

    @media screen and (orientation:portrait)
        div.main-panel 
            position fixed
            top 17vh
            left 0
            border-radius 10px 10px 0 0
            width 100%
            overflow-y auto
            height 83vh
            min-height 83vh

</style>