<template>
    <button class="file-select-container">
        
        <selected-file 
            v-if="file"
            :file="file"
            @remove="removeFile"    
        />

        <label v-else class="file-select">
            <button type='button' class="select-button" @click="openFileInput">
                <span>Create project from a CSV file </span>
                <!-- <input alt="select csv file from your device" type="file" accept=".csv, .xlsx" @change="addFile"/> -->
            </button>
            <input alt="select csv file from your device" id="fileInput" type="file" accept=".csv, .xlsx" @change="addFile"/>
        </label>
        
    </button>
</template>

<script>
import SelectedFile from '../create-shoot/SelectedFile'
export default {
    components: {
        SelectedFile
    },
    data() {
        return {
            file: null,
        }
    },
    methods: {
        openFileInput() {
            document.getElementById('fileInput').click();
        },
        addFile(e) {
            this.file = e.target.files[0]
        },
        removeFile() {
            this.file = null
        }

    }
        
}
</script>

<style lang="stylus" scoped>
button.file-select-container 
    display flex
    flex-flow column
    justify-content center
    align-items center
    maring-top 3rem
    
    label.file-select 
        display flex
        flex-flow column
        align-items center

        button.select-button 
            color: var(--text);
            font-size 4vmin
            border none
            background-color: var(--accent)
            font-family var(--font)
            text-align: center;
            overflow hidden
            border-radius 7px

            &:hover
                cursor pointer
                // transform scale(1.05)

        input[type="file"]
            display: none;
            width 1px 
            height 1px

    @media (orientation: portrait) 
        label.file-select 
            button.select-button 
                width 55vmin

    

    
</style>