import firebase from "firebase/app";
import "firebase/auth";
import {router} from '../../router'

export default {
actions: {
    async getSessionStorage() {
        const key = Object.keys(window.sessionStorage).find(x=> x.startsWith('firebase'))
        const obj =  await JSON.parse(window.sessionStorage[key])
        const owner = obj.uid
        return owner
    },

    async watchSession({ dispatch }) {
        let owner = await dispatch('getSessionStorage')
        firebase.auth().onAuthStateChanged((user) => {
            console.log('email verified:$$$$$$$$$ ', user.emailVerified)
            if (user.emailVerified) {
                dispatch('authenticate', owner)
                router.push('/projects')
            } else {
                this.$store.dispatch('callToast', "Error: No session.")
            }
        })
    },
    
    async login({ commit, dispatch }, payload) {
        try {
            await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
            let res = await firebase.auth().signInWithEmailAndPassword(payload.email, payload.password);
            if (res.user.emailVerified) {
                await dispatch('authenticate', res.user.uid)
                commit('setLoading', {'dataName': 'login', 'status': false})
            } else {
                await dispatch('verifyEmail')
                await dispatch('callToast', `Error: Verify your email address.`)
                commit('setLoading', {'dataName': 'login', 'status': false})
            }
        } catch(err) {
            commit('setLoading', {'dataName': 'login', 'status': false})
            dispatch('callToast', `Error: ${err.message}`)
        }
    },
    async verifyEmail({dispatch}) {
        try {
            await firebase.auth().currentUser.sendEmailVerification()
        } catch (err) {
            dispatch('callToast', `Email sent to verify your email address.`)
        }

    },
    authenticate({ commit }, owner) {
        try {
            commit('setAuthFlag', true)
            commit('setOwner', owner)
        } catch (err) {
            console.log('authenticate: ', err)
        }
        
    },

    resetPassword({ dispatch }, payload) {
        if ( !payload ) { dispatch('callToast', "Error: Enter your email address. Password not required.")}

        firebase.auth().sendPasswordResetEmail(payload)
            .then(() => {
                
                dispatch('callToast', "Email sent")
            })
            .catch((err) => {
                dispatch('callToast', `Error: ${err.message}`)
            });
    },

    async logout({ commit, dispatch }) {
        console.log('logout')
        try {
            sessionStorage.removeItem('vuex');
            sessionStorage.clear()
            commit('resetState')
            dispatch('callToast', 'Signed out')
        } catch (err) {
            dispatch('callToast', 'Error: Sign out FAILURE')
        }
        

    },

    async forceTokenRefresh() {
        try {
            let token = await firebase.auth().currentUser.getIdToken(true)
            return token
        } catch (err) {
            console.log('forceTokenRefresh ERRR: ', err)
        }
    }
}
}