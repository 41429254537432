<template>
    <button alt="upload button" class='upload-button'>
        <img src='../../../public/img/upload.svg' alt='upload icon'/>
        <div>Upload</div>
    </button>
</template>

<style lang="stylus" scoped>
    button.upload-button
        display flex
        flex-flow column
        background var(--charcoal)
        border none 
        border-radius 7px
        color var(--text)
        padding 3vmin
        font-family var(--font)
        display block
        margin 2rem auto 1rem auto

        img 
            height 8vmin
            filter var(--svg-filter)

        div
            color white
        
        &:hover
            cursor pointer
            transform scale(1.1)
</style>