<template>
<div>
    <spinner 
        v-if="loading" 
        bg='panel'
    />
    <main-panel>
        <div class="inner-panel">
            <h2>Project: {{projectName}}</h2>
            <form id="add-subject" @keyup.enter="submitSubject">
                <label for="first-name">First Name</label>
                <input id='first-name' type="text" required >
                <label for="last-name">Last Name</label>
                <input id='last-name' type="text" required >

                <template v-for="(item, i) in groups" :key="i">
                    <label :for="item">{{item}}</label>
                    <input :id='item' type="text" >
                </template>
                
                

            </form>
            <button alt="Add new student to project." type='submit' class="submitBtn" @click.prevent="submitSubject">
                Submit
            </button>
        </div>

    </main-panel> 
</div>
</template>

<script>
export default {
    methods: {
        async submitSubject() {
            const inputs = document.getElementById("add-subject").elements
            const payload = {
                firstName: null,
                lastName: null,
            }
            
            for (let i = 0; i < inputs.length; i++) {
                if(inputs[i].id === "first-name") {
                    payload.firstName = inputs[i].value.trim()
                }
                if(inputs[i].id === "last-name") {
                    payload.lastName = inputs[i].value.trim()
                }
                if (i > 1) {
                    payload[this.groups[i-2]] = inputs[i].value.trim()
                }
            }
            try {
                await this.$store.dispatch('addSubject', payload)
                await new Promise(res => setTimeout(res, 200));
                await this.$store.dispatch('fetchSubjects')
                
                this.$router.back()
            } catch(err){
                console.log('submit new subject ERRR: ', err)
            }
        }
    },
    computed: {
        groups() {
            return this.$store.getters.getSchema
        },
        projectName() {
            return this.$store.getters.getProjectName
        },
        loading() {
            return this.$store.state.uiStore.status.subjects.loading
        },
    }
    
}
</script>

<style lang="stylus" scoped>
    
    .inner-panel
        background var(--brighter-panel)
        border-radius 7px
        width 70vw
        display flex 
        flex-flow column
        align-items center

        form 
            display flex 
            flex-flow column
            align-items center
            
            label
                border none
                font-size: 4vmin
                &:first-child
                    margin-top 3vmin
                    
            input
                -webkit-appearance: none; 
                appearance: none;
                width: 45vmin
                font-size 2.6vmin
                padding: 1.3vmin 2.6vmin;
                margin-bottom 1.5rem
                display: inline-block;
                border solid 2px var(--border)
                border-radius: 7px;
                box-sizing: border-box;
                color var(--text)
                text-align center
                background-color: var(--panel)
                font-family var(--font)
                
                &:focus
                    background var(--bg)
                    
                &::placeholder 
                    opacity 1
                    text-align center
                    color var(--text)
                
        button.submitBtn
            -webkit-appearance: none; 
            appearance: none;
            background var(--charcoal)
            color var(--text-color)
            font-family: 'Roboto Slab', serif; 
            font-size calc(.7rem + .7vw)
            border none 
            border-radius 7px
            box-shadow 1px 1px 3px 1px rgba(0,0,0,.5)
            width 20vw
            margin 2rem

            &:hover
                cursor pointer
                transform scale(1.1)
    
    select.form-select::placeholder
        text-align center
    
    

</style>