export default {
actions: {
    async fetchSubjects ({ dispatch, rootState, commit }) { // eslint-disable-line
        commit('setLoading', {'dataName': 'subjects', 'status': true})
        const token = await dispatch('forceTokenRefresh')

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`)
        myHeaders.append("Content-Type", "application/json");

        const owner = rootState.owner
        const project = rootState.projectId
        const raw = JSON.stringify({
            "owner": owner,
            "project": project
        });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        try {
            const res = await fetch("https://gateway.audax.dev/list-subjects", requestOptions)
            const subjects = await res.json()
            if(subjects.length === 0) {
                commit('setHasSubjects', false)
                await commit('setSubjects', [])

                commit('setLoading', {'dataName': 'subjects', 'status': false})

            } else {
                await commit('setHasSubjects', true)
                await commit('setSubjects', subjects)
                await commit('setGroups')
                commit('setLoading', {'dataName': 'subjects', 'status': false})
            }
        } catch (err) {
            err => console.log('actions - fetchSubjects ☻ ', err)
        }
        
    },

    async addSubject({dispatch, rootState}, payload ) {
        const token = await dispatch('forceTokenRefresh')
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`)
        myHeaders.append("Content-Type", "application/json");
        console.log('addSubject payload: ', payload);
        const obj = {
            "owner":rootState.owner,
            "project":rootState.projectId,
            "firstName": payload.firstName,
            "lastName":payload.lastName,
            "groups": {}
        };
        for(let prop in payload) {
            if(!(prop in obj)) {
                obj.groups[prop] = payload[prop]
            }
        }
        
        const raw = (JSON.stringify(obj))
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        try {
            let res = await fetch("https://gateway.audax.dev/add-subject", requestOptions)
            let text = await res.text()
            console.log('add sub res: ', text)
            dispatch('callToast',  `${obj.lastName}, ${obj.firstName} added.`)
        } catch (err) {
            dispatch('callToast', `Error: ${err.message}`)
        }
    },

    async editSubject({dispatch, rootState}, payload ) {
        const token = await dispatch('forceTokenRefresh')
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`)
        myHeaders.append("Content-Type", "application/json");
        console.log('editSubject', payload)
        const obj = {
            "owner":rootState.owner,
            "id": payload.id,
            "firstName": payload.firstName,
            "lastName":payload.lastName,
            "groups": {}
        };
        for(let prop in payload) {
            if(!(prop in obj)) {
                obj.groups[prop] = payload[prop]
            }
        }
        console.log('editSubject ####2', obj)
        const raw = (JSON.stringify(obj))
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        
        try {
            let res = await fetch("https://gateway.audax.dev/update-subject", requestOptions)
            let text = await res.text()
            console.log('editsub: ', text)
            dispatch('callToast', `${obj.firstName} ${obj.lastName} has been edited.`)
        } catch (err) {
            dispatch('callToast', `Error: ${err.message}`)
            console.log('editSubject errrr: ', err)
        }
    },

    async deleteSubject({dispatch, rootState}, payload) {
        const token = await dispatch('forceTokenRefresh')
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`)
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "owner": rootState.ownder,
            "project": rootState.projectId,
            "subject": payload
        });
        
        const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };
        
        fetch("https://gateway.audax.dev/delete-subject", requestOptions)
            .then(response => response.text())
            .then(result => console.log('deleteSubject errrr: ', result))
            .catch(error => console.log('deleteSubject errrr:', error));
    },
}
}
