<template>
<main-panel>
    <h1>{{projectName}}</h1>
    <h2>Select up to 3 headers:</h2>
    <template v-for="(head, i) in headers" :key="i">
        <div class="select-headers">
            <column-check-box :id="i" @chooseheader="selectHeader($event)"/>&nbsp;{{head}}
        </div>
    </template>

    <h2>What you're uploading:</h2>
        <table>
            <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <template v-for="(head, i) in chosenHeaders" :key="i">
                            <th>{{head}}</th>
                    </template>
            </tr>

            <template v-for="(row, l) in rows.slice(0,10)" :key="l">
                <tr>
                        <td>{{row[0]}}</td>
                        <td>{{row[1]}}</td>
                        <td v-if="isHeaders[0]">{{row[2]}}</td>
                        <td v-if="isHeaders[1]">{{row[3]}}</td>
                        <td v-if="isHeaders[2]">{{row[4]}}</td>
                        <td v-if="isHeaders[3]">{{row[5]}}</td>
                        <td v-if="isHeaders[4]">{{row[6]}}</td>
                        <td v-if="isHeaders[5]">{{row[7]}}</td>
                        <td v-if="isHeaders[6]">{{row[8]}}</td>
                        <td v-if="isHeaders[7]">{{row[9]}}</td>

                </tr>
            </template>
        </table>
        
            <div v-if="this.chosenHeaders.length > 3" class="warn"><h2>less than 4 plz</h2></div>
            <upload-btn 
                v-if="isHeaders.some((head) => head == true) && chosenHeaders.length < 4"
                @click="upload"
            />

    <toast v-if="newMsg" offset="true"/>
    
</main-panel>
</template>

<script>
import UploadBtn from '@/components/ui/UploadBtn.vue' 
import ColumnCheckBox from '@/components/create-shoot/ColumnCheckBox.vue'
export default {
    components: {ColumnCheckBox, UploadBtn},
    data() {
        return {
            isHeaders: [],
        }
    },
    methods: {
        selectHeader(payload) {
            this.isHeaders[payload.id] = !this.isHeaders[payload.id]
        },
        async upload() {
            console.log('bwee', this.chosenHeaders);
            let id = await this.$store.dispatch('createProject', this.chosenHeaders)
            let payload = {"headers": this.isHeaders, "id": id}
            await this.$store.dispatch('loadSubjects', payload)
            await this.$store.dispatch('fetchProjects')
            this.$router.push({ name: "projects" });            
        }
    },
    computed: {
        projectName() {
            return this.$store.state.uploadStore.uploadProjectName
        },
        chosenHeaders() {
            return this.headers.filter((head, i) => this.isHeaders[i] == true)
        },
        headers() {
            return this.$store.state.uploadStore.uploadHeaders
        },
        rows() {
            return this.$store.state.uploadStore.uploadRows
        },
        newMsg() {
            return this.$store.state.uiStore.isNewMsg
        }
    },
    created() {
        this.isHeaders = new Array(this.headers.length).fill(false)
    },
    watch: {
        chosenHeaders: function(val) {
            console.log('',val)
        }
    }
}
</script>

<style lang="stylus" scoped>
    h1 
        font-size 5vmin
    div.select-headers
        font-size 3.5vmin
        display block
        float left

    h2 
        display block
        font-size 4vmin
    
    table 
        margin 0 4vmin
        font-size 2vmin
        th
            padding 2vmin
            border 4px var(--brightest-panel) solid
        td
            border 1px var(--brightest-panel) solid
            padding 1vmin
    div.warn 
        color var(--warn)
        

</style>
