export default {
    setSubjects(state, subjects) {
        console.log('',subjects)
        if (subjects instanceof Array) {
            let sorted = subjects.sort((a,b) => a.lastName.localeCompare(b.lastName))
            
            state.subjects = sorted
        }
    },
    setProjectId(state, projectId) {
        state.projectId = projectId
    },
    setAuthFlag(state, flag) {
        state.isAuthenticated = flag
    },
    setOwner(state, owner) {
        state.owner = owner
    },
    
    setProjects(state, projects) {
        // console.log('setProejects: ', projects)
        if (projects instanceof Array) {
            state.projects = []
            state.projects = projects.sort((a, b) => a.name.localeCompare(b.name))
        }
    },
    setGroups(state) {
        const keys = Object.keys(state.subjects[0].groups)
        keys.sort((a,b) => a.localeCompare(b))
        const groupsObj = {}
        let arr = []

        keys.forEach((key) => {
            arr = [...new Set(state.subjects.map(subject => subject.groups[key]))]
            groupsObj[key] = arr
        })
        
        state.groups = groupsObj
    },
    setHasProjects(state, bool) {
        state.hasProjects = bool
    },
    setHasSubjects(state, bool) {
        state.hasSubjects = bool
    },
    setLimit(state, limit) {
        state.listSizeLimit = limit
    },
    resetState(state) {
        state.subjects = []
        state.projects = []
        state.projectId = ''
        state.owner = ''
        state.groups = {}
        state.isAuthenticated = false
    }
    
    
    
    
    
}