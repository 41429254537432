export default {
    searchSubjects(state) {
        if(state.subjects.length === 0) {
            return "Project has no subjects."
        } else {
        const results =  state.subjects.filter(subject => {
            // set all flags to false initially
            let filters = Object.keys(state.groups)
            let passSelect0 = false;
            let passSelect1 = false;
            let passSelect2 = false;
            let passQuery = false;
            let select0 = state.uiStore.select0;
            let select1 = state.uiStore.select1;
            let select2 = state.uiStore.select2;
            // console.log('filters:::::',filters)
            if (filters.length === 0) {
                if (state.uiStore.query.length === 0) {
                    passQuery = true; 
                } else if (subject.lastName.toLowerCase().startsWith(state.uiStore.query.toLowerCase())) {
                    passQuery = true
                }
                return passQuery
            }

            if (filters.length === 1) {
                if (select0.value === '[ALL]' || subject.groups[select0.key] === select0.value) {
                    passSelect0 = true;
                }
                if (state.uiStore.query.length === 0) {
                    passQuery = true; 
                } else if (subject.lastName.toLowerCase().startsWith(state.uiStore.query.toLowerCase())) {
                    passQuery = true
                }
                return passSelect0 && passQuery
            }

            if (filters.length === 2) {
                if (select0.value === '[ALL]' || subject.groups[select0.key] === select0.value) {
                    passSelect0 = true;
                }
                if (select1.value === '[ALL]' || subject.groups[select1.key] === select1.value) {
                    passSelect1 = true;
                }
                if (state.uiStore.query.length === 0) {
                    passQuery = true; 
                } else if (subject.lastName.toLowerCase().startsWith(state.uiStore.query.toLowerCase())) {
                    passQuery = true
                }
                return passSelect0 && passSelect1 && passQuery
            }

            if(filters.length === 3) {
                if (select0.value === '[ALL]' || subject.groups[select0.key] === select0.value) {
                    passSelect0 = true;
                }
                if (select1.value === '[ALL]' || subject.groups[select1.key] === select1.value) {
                    passSelect1 = true;
                }
                
                if (select2.value === '[ALL]' || subject.groups[select2.key] === select2.value) {
                    passSelect2 = true;
                }
                
                if (state.uiStore.query.length === 0) {
                    passQuery = true; 
                } else if (subject.lastName.toLowerCase().startsWith(state.uiStore.query.toLowerCase())) {
                    passQuery = true
                }
                return passSelect0 && passSelect1 && passSelect2 && passQuery
            }
            
        });
        return results.slice(0, state.listSizeLimit);
        }
    },
    getSubject: (state) => (id) => {
        return state.subjects.find(subject => subject.id == id)
    },
    getDestructSubject: (state) => (id) => {
        let subjectObj = state.subjects.find(subject => subject.id == id)
        let sliced = Object.entries(subjectObj).slice(4,6)
        let groups = Object.entries(subjectObj.groups)
        sliced.push(...groups)
        return sliced
    },
    getProjectName(state) {
        let project = state.projects.find(project => project.id == state.projectId)
        return project.name
    },
    getSchema(state) {
        let project = state.projects.find(project => project.id == state.projectId)
        console.log('getshecma:::::::::::: ', project.schema.header)
        return project.schema.header
    },
    hasProjects(state) {
        if(state.projects > 0) {
            return true
        } else {
            return false
        }
    }
    
    
    
}