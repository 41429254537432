export default {
    state: {
        isAsking: false,
        query: '',
        networkMsg: null,
        isNewMsg: false, 
        isTips: false,
        select0: {
            value: null,
            key: null
        },
        select1: {
            value: null,
            key: null
        },
        select2: {
            value: null,
            key: null
        },
        status: {
            projects: {
                loading: false,
            },
            subjects: {
                loading: false
            },
            login: {
                loading: false
            }
        }
    },
    mutations: {
        setQuery(state, query) {
            state.query = query
        },
        setIsAsking(state, payload) {
            if(state.isAsking) {
                payload = false
            } else {
                payload = true
            }
            state.isAsking = payload
        },
        setSelect(state, {select, num}) {
            state[num].value = select
        },
        setSelectKey(state, {key, num}) {
            state[num].key = key
        },
        setLoading(state, {dataName, status}) {
            state.status[dataName].loading = status

        },
        setNetworkMsg(state, msg) {
            console.log('network msg: ', msg)
            state.networkMsg = msg
        },
        setIsNewMsg(state, bool) {
            state.isNewMsg = bool
            console.log('',state.isNewMsg)
        },
        setSelectsAll(state) {
            state.select0.value = "[ALL]" 
            state.select1.value = "[ALL]" 
            state.select2.value = "[ALL]" 
        },
        setTips(state, bool) {
            state.isTips = bool
        },
        
    },
    actions: {
        async callToast({commit}, msg) {
            commit('setIsNewMsg', true)
            commit('setNetworkMsg', msg)
            await new Promise(resolve => setTimeout(resolve, 5000))
            commit('setIsNewMsg', false)
        },
        
    }

    
}

