<template>
    <div class="background" :class="this.bg">
        <div class="foreground">
            <img v-if="isDark" src="../../../public/img/blue.svg" alt="logo">
            <img v-else src="../../../public/img/magenta-logo.svg" alt="logo">
        </div>
    </div>
</template>

<script>
export default {
    props: ['bg'],
    computed: {
        isDark() {
            let bool
            let theme = localStorage.getItem('theme')
            if (theme === "dark" || !theme) {
                bool = true
            } else { bool = false}
            return bool
        }
    }
}
</script>

<style scoped lang='stylus'>



    div.background
        background-color hsla(0, 0%, 10%, .7)
        position: fixed;
        display: flex;
        align-items: center;

    div.panel
        top 9.6vh
        left 23vw
        width 77vw
        height 90vh
        z-index: 1;
        border-top-left-radius 10px;

        div.foreground
            margin-left: auto;
            margin-right: auto;
            margin-bottom 35%
            

    div.background.login 
        display block
        position relative
        bottom auto
        right auto
        background none

        
        div.foreground
            margin auto 0
    

    img 
        width: 13rem;
        height: 13rem;
        animation spin 
        animation-duration 4s
        animation-iteration-count infinite
        animation-timing-function: ease

    @media screen and (orientation:portrait) 
        div.panel 
            position fixed
            top 18vh
            left 0
            border-radius 10px 10px 0 0
            width 100%
            overflow-y auto
            height 82vh
            min-height 82vh

    @keyframes spin 
        0%  
            transform: rotate(0deg) 
        100%  
            transform: rotate(-1080deg) 


    @keyframes background-transition 
        0%  
            background-position 1% 0% 
        50%  
            background-position 99% 100% 
        100%  
            background-position 1% 0% 

</style>