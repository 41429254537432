<template>
    <span class="toggletip-container" v-if="isTips">
        <button 
            @click="openTip" 
            v-click-away="closeTip"
            @keydown.esc="closeTip"
            type="button" 
        >
            <span  
                v-if="isTipToggled" 
                :class="this.contentSide" 
                class="content"
            >{{content}}</span>
        i</button>
    </span>
</template>

<script>
import { directive } from "vue3-click-away";
export default {
    props: ['content', 'contentSide'],
    data() {
        return {
            isTipToggled: false
        }
    },
    directives: {
        ClickAway: directive
    },
    methods: {
        closeTip() {
            this.isTipToggled = false
        },
        openTip() {
            this.isTipToggled = !this.isTipToggled
        }
    },
    computed: {
        isTips() {
            return this.$store.state.uiStore.isTips
        }
    }
}
</script>

<style lang="stylus" scoped>
@keyframes background-transition {
    0% {
        background-position 1% 0%
    }
    50% {
        background-position 99% 100%
    }
    100% {
        1% 0%
    }
}

.toggletip-container 
    position: relative;
    display: inline-block;
    align-self center

    button 
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        border: 0;
        background: var(--bg)
        font-weight: bold;
        color: var(--text-color)
        font-family var(--font)
        font-size 1rem
        // box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);


        &:focus 
            outline: none;
            box-shadow: 0 0 0 0.25rem var(--accent);

        &:hover 
            transform scale(1.05)
            cursor pointer 
            box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);

            
        span.content
            display: inline-block;
            position: absolute;
            top: -.6rem;
            width: clamp(6rem, 15vw, 15rem);
            padding: 1rem 0.5rem 1rem .5rem;
            font-size clamp(8px, .4rem+1vmax, 16px)
            background-image: (linear-gradient(to left, hsla(208, 38%, 44%, 1), hsla(312, 37%, 48%, 1)));
            animation: background-transition 10s ease infinite;
            background-size: 400% 400%;
            color: var(--text)
            border-radius 7px
            box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);


        .right
            left 2.5rem

        .left  
            right 2.5rem;
</style>