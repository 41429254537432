<template>
<div class="filters" v-if="selects">

    <button type="button" class="collapse" @click="expand">
        <img :class="{'rotate': isCollapsed}" src="../../../public/img/collapse.svg" alt="open or collapse filters row">
        <h3 class="filter-title" :class="{'hidden': !isCollapsed}">Filters</h3>    
    </button>
    
    <div class='filter-container' :class="{'hidden': isCollapsed}" v-if="keys[0]">
        <label >{{keys[0]}}</label>
        <select  :id='keys[0]' @click="setId(keys[0], i)" v-model="selectedOption0" alt="choose category to sort subjects on">
            <option value="[ALL]">[ALL]</option>
            <option v-for="(option, i) in selects[keys[0]]" :key="i">{{option}}</option>
        </select>
    </div> 
    <div class='filter-container' :class="{'hidden': isCollapsed}" v-if="keys[1]">
        <label >{{keys[1]}}</label>
        <select  :id='keys[1]' @click="setId(keys[1], i)" v-model="selectedOption1" alt="choose category to sort subjects on">
            <option value="[ALL]">[ALL]</option>
            <option v-for="(option, i) in selects[keys[1]]" :key="i">{{option}}</option>
        </select>
    </div> 
    <div class='filter-container' :class="{'hidden': isCollapsed}" v-if="keys[2]">
        <label >{{keys[2]}}</label>
        <select  :id='keys[2]' @click="setId(keys[2], i)" v-model="selectedOption2" alt="choose category to sort subjects on">
            <option value="[ALL]">[ALL]</option>
            <option v-for="(option, i) in selects[keys[2]]" :key="i">{{option}}</option>
        </select>
    </div> 
    
    <button type='button' @click="setAll" class='all' :class="{'hidden': isCollapsed}">
        ALL
    </button>
</div>
</template>

<script>
export default {
    data() {
        return {
            selectKey: null,
            isCollapsed: true 
        }
    },
    methods: {
        setId(key, i) {
            this.selectKey = key
            this.selectNum = i
        },
        setAll() {
            this.$store.commit('setSelectsAll')
        },
        expand() {
            this.isCollapsed = !this.isCollapsed
        }
    },
    computed: {
        selects() {
            return this.$store.state.groups
        },
        keys() {
            return Object.keys(this.selects)
        },
        selectedOption0: {
            get() {
                let num = 'select0'
                if (this.$store.state.uiStore[num]) { 
                    return this.$store.state.uiStore[num].value
                } else { return null }
            },
            set(select) {
                let num = 'select0'
                let key = this.selectKey
                console.log('selectedOption0 set', num, key);
                this.$store.commit('setSelect', { select, num})
                this.$store.commit('setSelectKey', { key, num})
            }
        },
        selectedOption1: {
            get() {
                let num = 'select1'
                if (this.$store.state.uiStore[num]) { 
                    return this.$store.state.uiStore[num].value
                } else { return null }
            },
            set(select) {
                let num = 'select1' 
                let key = this.selectKey
                console.log('selectedOption1 set', num, key);
                this.$store.commit('setSelect', { select, num})
                this.$store.commit('setSelectKey', { key, num})
            }
        },
        selectedOption2: {
            get() {
                let num = 'select2'
                if (this.$store.state.uiStore[num]) { 
                    return this.$store.state.uiStore[num].value
                } else { return null }
            },
            set(select) {
                let num = 'select2' 
                let key = this.selectKey
                console.log('selectedOption2 set', num, key);
                this.$store.commit('setSelect', { select, num})
                this.$store.commit('setSelectKey', { key, num})
            }
        },
        
    },
    
}

</script>

<style lang="stylus" scoped>
.hidden
    display none

// div.filters.column
//     flex-flow row
//     width auto

div.filters
    position relative
    display flex
    flex-flow row
    width 55vw
    justify-content space-evenly
    background var(--modal-background)
    border-radius 7px
    margin-bottom 1rem
    
    
        

    button.collapse
        position absolute
        top -1.5rem
        left -5rem
        border none
        background none
        color var(--text)
        font-size 2vmin
        font-family var(--font)

        &:hover
            transform scale(1.2)
            cursor pointer
        
        img 
            height 6vmin

        img.rotate 
            transform rotate(180deg)

        h3.filter-title 
            font-size 3vmin
            margin .4rem 0

    button.all
        border none
        background none
        color var(--text)
        font-size 1.2rem
        font-family var(--font)

        &:hover
            transform scale(1.2)
            cursor pointer

    div.filter-container
        margin 2rem 0 1rem 0
        background var(--panel)
        padding .2rem 1rem 1rem 1rem
        border-radius 7px
        
        label
            display block
            margin-bottom .3rem
            font-size 3vmin
        select 
            font-size 2.1vw
            width 14vw
            font-family var(--font)
            border-radius 7px
            border solid 2px var(--border)
            background var(--panel)
            color var(--text)
            text-align center
            
            &:focus
                background var(--bg)

</style>