import  { createApp } from 'vue';
import App from './App.vue'
import { router } from './router'
import { Store } from './store/index'
import "@/plugins/firebase";
import MainPanel from './components/ui/MainPanel.vue'
import Row from './components/ui/Row.vue'
import Spinner from './components/ui/Spinner.vue'
import ToggleTip from './components/ui/ToggleTip.vue'
import Toast from './components/ui/Toast.vue'




const app = createApp(App)
app.use(Store)
app.use(router)
app.component('spinner', Spinner)
app.component('toast', Toast)
app.component('main-panel', MainPanel)
app.component('flex-row', Row)
app.component('toggle-tip', ToggleTip)
app.mount('#app')

        
