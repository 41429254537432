import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import getters from './getters'
import mutations from './mutations'
import authActions from './modules/authActions'
import projectActions from './modules/projectActions'
import subjectActions from './modules/subjectActions'
import uiStore from './modules/uiStore'
import uploadStore from './modules/uploadStore'

export const Store = createStore({
    // this prevents the user from being logged out upon a page refresh
    // note that we only persist the fields that we really need to
    plugins: [createPersistedState({
        storage: window.sessionStorage,
        paths: ['projectId', 'authenticated']
    })],
    modules: {
        authActions,
        projectActions,
        subjectActions,
        uiStore,
        uploadStore
    },
    state: {
        subjects: [],
        projects: [],
        listSizeLimit: 200, 
        projectId: '',
        owner: '',
        isAuthenticated: false,
        groups: {},
        hasProjects: true, 
        hasSubjects: true,
    },
    getters,
    mutations,
});
