<template>
    <div class="side-bar" >  

        <router-link alt="project page link" to="/projects" class="link-container" :class="{disabled: !hasProjects}">
            <img src="../../../public/img/photoshoots.svg" alt="camera icon">
            <div class="link-title">Projects</div>
        </router-link>

        <router-link alt="create project link" to="/createProject" class="link-container ">
            <img src="../../../public/img/add-photoshoot.svg" alt="import icon">
            <div class="link-title">Create Project</div>
        </router-link>

        <router-link alt="manage projects link" to="/manage" class="link-container" :class="{disabled: !hasProjects}">
            <img src="../../../public/img/manage-shoots.svg" alt="manage icon">
            <div class="link-title">Manage Projects</div>
        </router-link>

        <router-link alt="account settings link" to="/settings" class="link-container">
            <img src="../../../public/img/account-settings.svg" alt="gear icon">
            <div class="link-title">Account</div>
        </router-link>
        
        <router-link alt="help page link" to="/help" class="link-container help">
            <img src="../../../public/img/help.svg" alt="help icon" >
            <div class="help-title">Help</div>
        </router-link>
        
    </div>
</template>

<script>
export default {
    data() {
        return {
            bool: false
        }
    },
    computed: {
        hasProjects() {
            return this.$store.state.hasProjects
        },
    },
    

}
</script>

<style lang="stylus" scoped>
    div.side-bar 
        width 21vw
        display inline-flex
        flex-flow column
        margin 0
        
        a.link-container
            display flex
            flex-flow row
            align-items center
            width auto
            height auto
            padding .4rem 1rem .4rem 0
            margin 1rem 0 0 0
            background var(--panel)
            border-top-right-radius:7px;
            border-bottom-right-radius:7px;
            text-decoration none 
            color var(--title)
            
            &:hover
                cursor pointer 
                transform translateX(.5rem)
                background-color var(--brighter-panel)
        
            div.link-title
                font-family var(--font)
                font-size 3.5vmin
                margin .5rem 0 

            img 
                margin 0 1rem
                height 4vmin
                filter var(--svg-filter)

        a.router-link-active  
            background var(--brightest-panel) 
            width 21.5vw 

        a.disabled 
            background var(--greyed-out)
            color var(--greyed-out-text)
            width 18vw
            font-size 1rem
            pointer-events: none;

            div.link-title
                font-family var(--font)
                font-size 1rem

            img 
                margin 0 1rem
                width 2vmin
                filter var(--greyed-svg)

        a.help  
            position absolute
            width auto
            bottom 0
            border-radius 0 7px 0 0

            &:hover
                transform:translate(.5rem,-.5rem)

            img
                margin 0 1rem
                height 2rem
                width 2rem
                filter var(--svg-filter)
            
            div.help-title 
                font-family var(--font)
                
        
    @media screen and (orientation:portrait) 
        div.side-bar 
            width auto
            height auto
            display flex
            flex-flow row
            justify-content space-around

            a.link-container
                margin 0 
                padding 0
                width 18vw
                border-radius 10px

                &:hover
                    transform none

                div.link-title
                    font-size 2.5vmin

                img 
                    margin 0 1vw
                    width 4vmin
                    filter var(--svg-filter)
    
            a.router-link-active  
                background var(--brightest-panel) !important
                transform translateY(-1vmin) !important
            
            a.help  
                position relative
                width auto
                bottom auto
                border-radius 7px
                font-size 2.5vmin


                img 
                    margin 0 1vw
                    filter var(--svg-filter)

                div.help-title 
                    font-family var(--font)
                    margin-right 2vw

            a.add-subject-link 
                position fixed
                bottom 0
                left 0
                background var(--brightest-panel)  
            

    
</style>
