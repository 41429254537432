<template>
    <div>
        <slot></slot>
    </div>
</template>

<style lang="stylus" scoped>
    div
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        background var(--panel)
        padding 6vh 4rem 4rem 4rem
        border-radius 10px
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
</style>