<template>
    <div class="page-container">

        <a alt="go back to previous page" href="javascript:history.back()" @click="goBack()">
            <img src="../../public/img/arrow.svg"/>
        </a>  

        <div class="info-section">
            <h1 class="info-label">First Name: {{subject.firstName}}</h1>
            <h1 class="info-label">Last Name: {{subject.lastName}}</h1>
            <h1 class="info-label" v-for="(attribute, key, i) in groups" :key="i">{{key}}: {{attribute}}</h1>
        </div>

        <div class="code-section">
            <div class="pacman-border">
                <img src="../../public/img/pacman.svg" alt="" class="top-left">
                <img src="../../public/img/pacman.svg" alt="" class="top-right">
                <img src="../../public/img/pacman.svg" alt="" class="bot-left">
                <img src="../../public/img/pacman.svg" alt="" class="bot-right">

                <table class="table">
                    <tr v-for="(row, i) in codeRows" :key=i>
                        <td class="table-elem" v-for="(symbol, j) in row" :key=j>
                            {{symbol}}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
    computed: {
        codeRows() {
            const chars = Array.from(this.subject.code);
            const rows = [];
            for (var i = 0; i < 8; i++) {
                rows.push(chars.slice(i * 8, (i + 1) * 8));
            }
            return rows;
        },
        subject() {
            return this.$store.getters.getSubject(this.$route.params.id)
        },
        groups() {
            let groups = this.$store.getters.getSubject(this.$route.params.id).groups
            let sorted = Object.keys(groups).sort()
            let sortedObj = {}
            sorted.forEach((x) => {
                let key = x.charAt(0).toUpperCase() + x.slice(1)
                sortedObj[key] = groups[x]
            })                
            return sortedObj
        }
    },
    methods: {
        goBack() {
            document.exitFullscreen();
        }
    }
    
}
</script>
<style scoped lang="stylus">
    div.page-container
        background white
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        overflow: hidden;
        display: flex;
        
        a 
            height 2.5rem
            width 3rem
            position: absolute;
            border: none;
            background: none;
            outline: none;
            z-index 1
            transform rotate(180deg)
            top 1.5rem
            left 1.5rem

            &:hover 
                transform: scale(1.2) rotate(180deg)
                cursor: pointer 
    
        div.info-section 
            font-family: NotoMono;
            color: black;
            overflow: hidden;
            display: flex;
            flex-flow column
    
        div.code-section
            display: flex;
            
            div.pacman-border 
                position relative
                display inline-block

                img 
                    width 9vmin
                .top-left
                    transform rotate(180deg)
                    position absolute
                .top-right
                    transform rotate(270deg)
                    position absolute
                .bot-left
                    transform rotate(90deg)
                    position absolute
                .bot-right
                    position absolute
                
                table.table 
                    position inline-block
                    table-layout fixed
                    font-family: NotoMono;
                    font-weight: bold;
                    color: black;
                    background-color white
                    margin 0vmin
                    padding-bottom 3px
                    padding-top -3px
        
                    td.table-elem
                        font-size: 8vmin
                        text-align: center

    @media (orientation: landscape) 
        div.page-container
            flex-flow row

            div.info-section
                width 32vw
                margin-top 4rem
                padding 3vh
                align-items: flex-start;

                h1.info-label 
                    margin: 1.5vh;
                    font-size clamp(1.25rem, 4.5vh, 4.5vh)
            
            div.code-section 
                width: 68vw
                justify-content: center;
                align-items: center;
                div.pacman-border
                    img 
                        width 9vh
                    offset = -4.5vh
                    .top-left
                        top offset
                        left offset
                    .top-right
                        top offset
                        right offset
                    .bot-left
                        bottom offset
                        left offset
                    .bot-right
                        bottom offset
                        right offset

                    table.table 
                        width 80vh
                        height 80vh

                        td.table-elem
                            font-size: 7.5vh

    @media (max-aspect-ratio: 10/7)
        div.page-container

            div.info-section
                h1.info-label 
                    font-size clamp(1.25rem, 3.15vw, 3.15vw)

            div.code-section 
                div.pacman-border
                    img 
                        width 6.3vw
                    offset = -3.15vw
                    .top-left
                        top offset
                        left offset
                    .top-right
                        top offset
                        right offset
                    .bot-left
                        bottom offset
                        left offset
                    .bot-right
                        bottom offset
                        right offset

                    table.table 
                        width 56vw
                        height 56vw

                        td.table-elem
                            font-size: 5.25vw

    @media (max-aspect-ratio: 1/1)
        div.page-container
            flex-flow column

            div.info-section
                justify-content: center;
                align-items: center;
                width 100%
                height calc(32vh - 3rem)
                margin-top 3rem
                h1.info-label 
                    margin: 0;
                    font-size 3.5vh
        
            div.code-section
                height 68vh 
                justify-content: center;
                align-items: center;

                div.pacman-border
                    img 
                        width 6.3vh
                    offset = -3.15vh
                    .top-left
                        top offset
                        left offset
                    .top-right
                        top offset
                        right offset
                    .bot-left
                        bottom offset
                        left offset
                    .bot-right
                        bottom offset
                        right offset

                    table.table 
                        width 56vh
                        height 56vh

                        td.table-elem
                            font-size: 5.25vh

        
    @media (max-aspect-ratio: 7/10) 
        div.page-container
            div.code-section
                div.pacman-border
                    img 
                        width 9vw
                    offset = -4.5vw
                    .top-left
                        top offset
                        left offset
                    .top-right
                        top offset
                        right offset
                    .bot-left
                        bottom offset
                        left offset
                    .bot-right
                        bottom offset
                        right offset

                    table.table 
                        width 80vw
                        height 80vw

                        td.table-elem
                            font-size: 7.5vw

</style>
