import { createRouter, createWebHistory } from 'vue-router'
import CreateProjectPage from './pages/CreateProjectPage.vue'
import ListSubjectsPage from './pages/ListSubjectsPage.vue'
import EditProjectPage from './pages/EditProjectPage.vue'
import CodePage from './pages/CodePage.vue'
import LoginPage from './pages/LoginPage.vue'
import ProjectsPage from './pages/ProjectsPage.vue'
import ManagePage from './pages/ManagePage.vue'
import SettingsPage from './pages/SettingsPage.vue'
import HelpPage from './pages/HelpPage.vue'
import AddSubjectPage from './pages/AddSubjectPage.vue'
import PageNotFound from './pages/PageNotFound.vue'
import SelectColumnsPage from './pages/SelectColumnsPage.vue'
import EditSubjectPage from './pages/EditSubjectPage.vue'
import ManualCreatePage from './pages/ManualCreatePage'
// import Test from './pages/Test.vue'
import { Store } from './store/index'


/**
 * Check to make sure that we're authenticated before accessing a protected route
 */


function authenticate(to, from, next) {
    if (Store.state.isAuthenticated === true) {
        next();
    } else {
        console.log('router err (function authenticate)', Store.getters.getAuthFlag)
        next('/login');
    }
}


const routes = [
    // {   
    //     path: '/test', 
    //     component: Test,
    // },
    {   
        path: '/:pathMatch(.*)*', 
        component: PageNotFound,
        name: 'not-found'
    },
    {
        path: '/login', 
        component: LoginPage
    },
    {
        path: '/createProject', 
        component: CreateProjectPage, 
        beforeEnter: authenticate
    },
    { 
        path: '/code/:id', 
        name: 'code', 
        component: CodePage, 
        props: true, 
        beforeEnter: authenticate
    },
    {
        path: '/projects', 
        name: 'projects',
        component: ProjectsPage, 
        beforeEnter: authenticate
    },
    {
        path: '/manage', 
        component: ManagePage, 
        beforeEnter: authenticate
    },
    {
        path: '/settings', 
        component: SettingsPage, 
        beforeEnter: authenticate
    },
    {
        path: '/help', 
        component: HelpPage, 
        beforeEnter: authenticate
    },
    {
        path: '/listSubjects/:id',
        name: 'listSubjects',
        component: ListSubjectsPage, 
        props: true,
        beforeEnter: authenticate
    },
    {
        path: '/editProject/:id',
        name: 'editProject',
        component: EditProjectPage, 
        props: true,
        beforeEnter: authenticate
    },
    {
        path: '/editSubject/:id',
        name: 'editSubject',
        component: EditSubjectPage, 
        props: true,
        beforeEnter: authenticate
    },
    {
        path: '/addsubject', 
        component: AddSubjectPage, 
        beforeEnter: authenticate
    },
    {
        path: '/table', 
        component: SelectColumnsPage, 
        beforeEnter: authenticate
    },
    {
        path: '/manual', 
        component: ManualCreatePage, 
        beforeEnter: authenticate
    }

]

export const router = createRouter({
    routes,
    history: createWebHistory(),
    //@ts-ignore  - currently no TS support for scrollBehavior
    scrollBehavior (to, from, savedPosition) { 
        return savedPosition
    }
})


