<template>

    <main-panel >
        <div class="container">
            <h1>Settings</h1>
            
            <hr>
                <flex-row class="modes">
                    <h2>Dark Mode</h2>
                    <color-switch/>
                    <h2>Light Mode</h2>
                </flex-row>
            <hr>
                <flex-row class="tips">
                    <h2>Tips: &nbsp;On</h2>
                    <tips-switch/>
                    <h2>Off</h2>
                </flex-row>
            <hr>

                <flex-row class="quit">
                    <h2>Quit Asking:</h2> 
                    <quit-check-box/>
                </flex-row>
            <hr>
                <flex-row class="limit">
                    <h2>List limit:</h2>
                    <input type="text" v-model="limit">
                </flex-row>
            <hr>
        </div>
    </main-panel>

</template>

<script>
import ColorSwitch from '../components/ui/ColorSwitch'
import TipsSwitch from '../components/ui/TipsSwitch'
import QuitCheckBox from '../components/manage/QuitCheckBox'

export default {
    components: { ColorSwitch, QuitCheckBox, TipsSwitch },
    computed: {
        theme() {
            console.log(localStorage.getItem('theme'))
            return localStorage.getItem('theme')
        },
        limit: {
            get() {
                return this.$store.state.listSizeLimit
            },
            set(val) {
                this.$store.commit('setLimit', val)
            }
        }
    },
    watch: {
        theme: function(res) { console.log(res) }
    }
}
</script>

<style lang="stylus" scoped>
div.container 
    margin-left 5vw

    h1 
        font-size 7vmin
    
    h2 
        font-size 4vmin

    hr 
        margin 2rem 0 2rem 0

    div.modes 
        align-items flex-end

        h2 
            margin 0 0 0 1rem

            &:first-child
                margin 0rem 1rem 0 0
    div.tips 
        align-items flex-end

        h2 
            margin 0 0 0 1rem

            &:first-child
                margin 0rem 1rem 0 0

    div.quit
        align-items center

        h2 
            margin 0 1rem 0 0 

    div.limit 
        h2 
            margin 0
        input
            -webkit-appearance: none; 
            appearance: none;
            max-width: 5rem
            max-height 2rem
            font-size 1rem
            padding: 0
            margin-left 1rem
            display: inline-block;
            border solid 2px var(--border)
            border-radius: 7px;
            box-sizing: border-box;
            color var(--text)
            text-align center
            background-color: var(--panel)
            font-family var(--font)

            &:focus
                background var(--bg)
</style>