<template>
<div>
    <spinner 
        v-if="loadingProjects || loadingSubjects" 
        bg='panel'
    />
    <main-panel>
        <template 
            v-for="(project, i) in projects" 
            :key="i" 
        >
            <flex-row>
                <button :class="{'first-project': i===0&&tips}" class="project-link" >
                    <div class="project-name">{{project.name}}</div>
                    <div class="details">
                        <div>Expiration: {{project.expiration}}</div>
                    </div>
                    <div class="buttons-row">
                        <button alt="Edit Subjects of this project." class='edit-buttons' @click.prevent="editSubjects(project.id)">
                            <img src="../../public/img/edit.svg" alt="edit subjects icon">
                        </button>
                        <toggle-tip id="tt" v-if="i===0" contentSide="left" content="Edit and delete subjects from this project"/>

                        <button alt="open prompt to delete this project." class='edit-buttons delete' @click="openDialog($event, project.id, i)">
                            <img src="../../public/img/delete.svg" alt="trash icon">
                        </button>
                        <toggle-tip id="tt" v-if="i===0" contentSide="left" content="Delete this project"/>

                        <delete-confirm 
                            v-if="isVisible" 
                            :id="id"
                            @close="isVisible = false"
                            @quit="quitAskingMode = !$event"
                            @delete-dom="deleteDom"
                        />
                    </div>
                </button>
            </flex-row>
        </template>
    </main-panel>

    <toast v-if="newMsg" offset="true"/>
    
</div>
</template>

<script>
import DeleteConfirm from '@/components/manage/DeleteConfirm.vue';
export default {
    components: { DeleteConfirm },
    data() {
        return {
            isVisible: false,
            id: null, 
            i: null,
        }
    },
    methods: {
        async editSubjects(id) {
            this.$store.commit('setProjectId', id); // change id of project in store
            await this.$store.dispatch("fetchSubjects")
            this.$store.commit("setSelectsAll")
            this.$router.push({name: 'editProject', params: {id: id}}); //go to new page with that id to edit students
        },
        async openDialog(x, id, i) {
            if(this.isAsking) {
                this.deleteDom( i)
                await this.$store.dispatch('deleteProject', id)
                await this.$store.dispatch('fetchProjects')
            } else {
                this.id = id
                this.i = i
                this.isVisible = true
            }
        },
        deleteDom(i) {
            console.log('deleteDom', i)
            this.projects.splice(i, 1)
        },
    },
    computed: {
        projects() {
            return this.$store.state.projects
        },
        isAsking() {
            return this.$store.state.uiStore.isAsking
        },
        hasSubjects() {
            return this.$store.state.hasSubjects
        },
        loadingSubjects() {
            return this.$store.state.uiStore.status.subjects.loading
        },
        loadingProjects() {
            return this.$store.state.uiStore.status.projects.loading
        },
        tips() {
            return this.$store.state.uiStore.isTips
        },
        newMsg() {
            return this.$store.state.uiStore.isNewMsg
        }
    },
    beforeCreate() {
        if(this.$store.state.projects.length < 1) {
            this.$store.dispatch('fetchProjects')
        }
        this.projects
    },
    
    
}
</script>

<style lang="stylus" scoped>
    button.project-link
        color: var(--text)
        text-decoration: none;
        font-family: var(--font)
        background var(--brighter-panel)
        border-radius 3px
        width 73vw 
        height 8vh
        margin 1.5vh 0
        border none
        display flex

        div.project-name    
            font-size 5vmin
            align-self center
            margin-left .8rem
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width 30vmin

        div.details
            font-size 2vmin
            align-self center
            margin-left auto
            margin-right 1.5rem

        div.buttons-row 
            font-size 1.2rem
            align-self center
            min-width 20vmin

        button.edit-buttons
                padding 0
                background none
                border none
                margin-right 1rem

                img 
                    height 6vmin
                    vertical-align: middle
                    filter var(--svg-filter)

                &:hover
                    cursor pointer
                    transform scale(1.1)
        
        button.delete
            &:hover
                filter brightness(0.3) sepia(1) hue-rotate(-40deg) saturate(12)

    button.first-project 
        div.buttons-row
            display flex
            flex-flow row

            button.edit-buttons
                margin 0

            #tt
                margin 0 .5rem 3rem .2rem 
</style>