<template>
    <a @click="openCode" class='center' alt="link to subject's code page or edit subject page" >
        {{subject.lastName}}, {{subject.firstName}}
    </a>
</template>

<script>
export default {
    props: ['subject', 'name'],
    methods: {
        openCode() {
            if(this.name === "code") {
                document.body.requestFullscreen();
                this.$router.push({name: this.name, params: {id: this.subject.id}})
            } else {
                this.$router.push({name: this.name, params: {id: this.subject.id}})
            }
        }
    }
};
</script>

<style scoped lang="stylus">
a.center
    grid-column-start 2
    text-decoration none
    color var(--text)
    font-weight 200
    font-family var(--font)
    font-size 3.4vmin
    padding .1rem 1.5rem
    background-color var(--brighter-panel)
    margin-bottom .3rem
    border-radius 3px
    width 25vw

    &:hover 
        cursor pointer 
        background-color var(--brightest-panel)

</style>