
export default {

actions: {
    async fetchProjects({ dispatch, commit, rootState }) {
        commit('setLoading', {'dataName': 'projects', 'status': true})
        const token = await dispatch('forceTokenRefresh')
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`)
        myHeaders.append("Content-Type", "application/json")
        
        let owner = rootState.owner
        const raw = JSON.stringify({
            "owner": owner
        });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,  
            redirect: 'follow'
        };
        
        try {
            let res = await fetch("https://gateway.audax.dev/list-projects", requestOptions)
            let data =  await res.json()
            if(data.length == 0) {
                commit('setHasProjects', false)
                commit('setLoading', {'dataName': 'projects', 'status': false})
                return '💀'
            } else {
                commit('setHasProjects', true)
                commit('setProjects', data)
                commit('setLoading', {'dataName': 'projects', 'status': false})
                return '👍'
            }
        } catch(err) {
            err => console.log('actions - fetchProjects: ', err)
        }
    },

    async deleteProject({dispatch, rootState}, id) {
        const token = await dispatch('forceTokenRefresh')
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`)
        myHeaders.append("Content-Type", "application/json")
        const raw = JSON.stringify({
            "owner": rootState.owner,
            "id": id
        });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        try {
            let res = await fetch("https://gateway.audax.dev/delete-project", requestOptions)
            let text = await res.text()
            console.log('delProj: ', text)
            dispatch('callToast', `Project: ${id} deleted.`)
        } catch (err) {
            console.log('delete project: ', err)
            dispatch('callToast', `Errow: ${err.message}`)

        }
    },

}
}

