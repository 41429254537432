<template>
<div>
    <spinner 
        v-if="loading" 
        bg='panel'
    />
    <main-panel>
        <h3>Project: {{projectName}}</h3>
        <subject-search-bar />

        <div class="listContainer" v-if="hasSubjects">
            <div class="grid">
                <subject-selects v-if="hasGroups" class="center"/>

            </div>
            
            <template  v-for="(subject, i) in searchSubjects" :key='i' class="list" >
                <div class="list-item-grid">
                    <toggle-tip  class="left" v-if="i===0" contentSide="left" content="Edit this subject"/>
                    <ListItem class="center"  :subject='subject' name="editSubject"/>
                    <trash-btn 
                        alt="delete subject from project"
                        v-if="deleteMode" 
                        size="two" 
                        @click="deleteSubject($event, subject.id, i)"
                    />
                </div>
            </template>
        </div>
        <div v-else>
            <h1>This Project has no subjects.</h1>
            <ul>
                <li><h3>Add subjects individually with the add subjects button.</h3></li>   
                <li><h3>Reupload your CSV.</h3></li>
            </ul>
        </div>
        
        <flex-row class="btn-row">
            <button 
                alt="toggle delete mode on for deleting subjects" 
                class="delete-mode" 
                @click="this.deleteMode = !this.deleteMode" 
                :class="{'active': this.deleteMode}"
                type="button"
            >
                <img src="../../public/img/delete.svg" alt="delete icon">
                Delete subjects
            </button>

            <router-link alt="add subjects link" to="/addsubject"   
                class="add-subject-link"
            >    
                <img src="../../public/img/add-subject.svg" alt="add-user icon">
                <div class="link-title">Add Subject</div>
            </router-link>
        </flex-row>
    </main-panel>

    <toast v-if="newMsg" offset="true"/>
    
</div>
</template>

<script>
import SubjectSelects from "../components/list/SubjectSelects.vue"
import ListItem from "../components/list/ListItem";
import TrashBtn from "../components/ui/TrashBtn";
import SubjectSearchBar from "../components/list/SubjectSearchBar.vue"


export default {
    components: {
        ListItem, SubjectSelects, TrashBtn, SubjectSearchBar
    },
    data(){
        return {
            deleteMode: false,
            listKey: 1,
        }
    },
    methods: {
        async deleteSubject(e, id, i) {
            console.log(id, i);
            await this.$store.dispatch('deleteSubject', id)
            await new Promise(resolve => setTimeout(resolve, 100));
            await this.$store.dispatch('fetchSubjects')
            await this.searchSubjects.splice(i, 1)
            this.listKey++
            
            
        }
    },
    computed: {
        searchSubjects() {
            return this.$store.getters.searchSubjects
        },
        projectName() {
            return this.$store.getters.getProjectName
        },
        hasSubjects() {
            return this.$store.state.hasSubjects
        },
        loading() {
            return this.$store.state.uiStore.status.subjects.loading
        },
        hasGroups() {
            let bool = false
            if(Object.keys(this.$store.state.groups).length > 0) {
                bool = true
            }
            return bool
        },
        newMsg() {
            return this.$store.state.uiStore.isNewMsg
        }

    },
    beforeUnmount() {
        this.$store.commit('setQuery', '')
    }
}
</script>

<style lang="stylus" scoped >
    h3 
        margin 1rem
        font-size 2.5rem
            
    div.listContainer
        display flex
        flex-flow column
        align-items center
        font-size calc(1rem + 1vw)
        font-family: var(--font)
        text-align center
        margin-top 1rem

        div.grid
            display: grid;
            grid-template-columns 4rem 2rem 1fr 2rem 4rem
            margin 0 1vw 0 1vw
            
            .center
                grid-column-start: 3;

        div.list-item-grid
            display: grid;
            grid-template-columns 1fr 3rem auto 3rem 1fr

            .left 
                grid-column-start 2
                margin-bottom .7rem
                
            .center
                grid-column-start 3

    div.btn-row
        width 74vw
        position sticky
        bottom 1vmax
        justify-content space-between

        button.delete-mode 
            border none
            display flex
            flex-flow row
            align-items center
            padding 1vmin
            background var(--panel)
            border-radius 1vmin
            text-decoration none 
            color var(--title)
            font-family var(--font)
            font-size 2.6vmin
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

            &:hover
                cursor pointer 
                transform scale(1.05)
                background-color var(--brighter-panel)

            img 
                margin-right .5rem
                height 3.5vmin
                filter var(--svg-filter)

        button.active 
            background var(--accent)
            color white
            transform scale(1.1)

        a.add-subject-link
            display flex
            flex-flow row
            align-items center
            padding 1vmin
            background var(--panel)
            border-radius 1vmin
            text-decoration none 
            color var(--title)
            font-family var(--font)
            font-size 3vmin
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            
            &:hover
                cursor pointer 
                transform scale(1.05)
                background-color var(--brighter-panel)
        
            img 
                margin-right .5rem
                height 3.5vmin
                filter var(--svg-filter)

    @media screen and (orientation:portrait)
        div.btn-row
            width 96vw

        


</style>